@import '../../scss/theme-bootstrap';

.elc-product-brief {
  height: 100%;
  display: flex;
  flex-direction: column;
  // remove condition in ASMBLY3-1246
  @if $cr21-images == true {
    background: $color-cta-grey--light;
  }
  padding: 14px 20px;
  @include breakpoint($bp--medium-up) {
    padding: 22px 20px;
  }
  .elc-size-picker-container {
    &.multiple-sizes {
      .elc-dropdown {
        margin: 0;
        &-wrapper {
          width: auto;
          margin: 0 auto;
          padding-#{$ldirection}: 10px;
        }
        &-input-wrapper {
          .elc-price-and-size {
            width: 100%;
            flex-direction: row;
            justify-content: flex-end;
          }
        }
      }
      & ~ .elc-product-prices-clickable-wrapper {
        display: none;
      }
    }
  }
  .elc-size-picker-wrapper {
    margin: 0 auto;
    width: auto;
    display: flex;
    .elc-select-a-size {
      display: none;
    }
    .elc-size,
    .elc-formatted-price {
      @include body-text;
      color: $color--black;
    }
    .elc-formatted-price {
      text-transform: uppercase;
    }
    .elc-size {
      white-space: nowrap;
    }
    .elc-formatted-price {
      margin-#{$rdirection}: 10px;
    }
  }

  .elc-main-content-section {
    display: flex;
    flex-direction: column;
  }
  .elc-footer-content-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  a,
  a:visited {
    border: 0;
  }
  .elc-product {
    &-display-name-wrapper {
      z-index: 2;
      h1,h2 {
        @include sub-title--bold();
        font-family: $product-display-name;
        width: 100%;
        max-width: 314px;
        margin: auto;
        text-align: center;
        text-transform: uppercase;
        @include breakpoint($bp--medium-up) {
          min-height: 40px;
        }
      }
    }
    &-sub-display-name {
      text-align: center;
    }
    &-prices-wrapper,
    &-prices-clickable-wrapper {
      display: flex;
      margin: 0 auto 6px;
      flex-wrap: wrap;
      justify-content: center;
    }
    &-price-row-wrapper {
      display: flex;
      margin: 0;
      .elc-price-formatted-wrapper ~ .elc-price-formatted-wrapper {
        margin-#{$ldirection}: 10px;
      }
    }
    &-price-per-unit-row-wrapper {
      width: 100%;
    }
    &-price-per-unit {
      margin: 0;
      text-align: center;
    }
    &-original-price,
    &-price {
      font-family: $ano_regular-font;
      font-size: 16px;
      line-height: 18px;
      margin: 0;
    }
    &-image-badge-wrapper {
      border: 0;
      display: flex;
      width: calc(100% + 40px);
      position: relative;
      margin-#{$ldirection}: -20px;
      z-index: 1;
    }
    &-images-wrapper {
      position: relative;
      width: 100%;
      img {
        height: auto;
        display: block;
        @include breakpoint($bp--large-up) {
          height: 100%;
        }
      }
    }
    &-badge {
      font-family: $ano-bold_regular-font;
      font-size: 12px;
      line-height: 14.4px;
      position: absolute;
      #{$rdirection}: 20px;
      padding: 2px 5px;
      background: $color--white;
      border-radius: 25px;
      border: 1px solid $color-cta-grey;
    }
    &-shade-name-wrapper {
      @include sub-title--bold();
      text-align: center;
      margin: 10px 0;
    }
    &-full-cta-wrapper,
    &-cta-wrapper {
      margin-top: 0;
      width: unset;
      position: inherit;
      box-shadow: unset;
      background: unset;
      .elc-add-to-bag-button,
      .elc-add-to-collection-button,
      .elc-shop-now-button,
      .elc-preorder-button,
      .elc-product-notify-me-button {
        @include body-text--small-bold();
        width: auto;
        border-radius: 50px;
        padding: 0 20px;
        line-height: 14px;
        height: 45px;
      }
      .coming-soon,
      .sold-out {
        button {
          background-color: $color--white;
          cursor: default;
        }
      }
      .elc-preorder-button {
        background-color: $color--white;
        cursor: default;
      }
    }
    &-rating-wrapper {
      justify-content: flex-start;
      .elc-rating {
        margin: 0;
      }
      .elc-average-rating-text {
        display: none;
      }
      .elc-stars-wrapper {
        width: 70px;
      }
      .elc-rating-reviews {
        @include body-text--small();
        padding-top: 5px;
      }
    }
  }
  .elc-rating-component {
    margin: -3px 0 0;
    @include breakpoint($bp--large-up) {
      margin: 0;
    }
  }
  .elc-selected-size-space-row {
    display: none;
  }
  h2.elc-product-short-description-wrapper,
  .elc-product-great-to-know-wrapper {
    @include body-text();
    color: $black;
    max-width: 380px;
    width: 100%;
    text-align: center;
    text-transform: none;
    margin: 0 auto;
  }
  .elc-add-to-bag-sku-inventory-status-message-message {
    @include body-text--small();
  }
  .elc-single-shade-wrapper {
    padding: 0;
    flex-direction: column;
    .elc-subtitle--3 {
      @include body-text--small();
      font-size: 14px;
      text-transform: uppercase;
      font-family: $product-shade-name;
      color: $color-shade-name;
    }
    .elc-shade-icon {
      width: 30px;
      height: 30px;
      margin-bottom: 8px;
    }
  }
  .elc-great-to-know-container {
    margin-block-end: 12px;
  }
  .elc-shade-swatches-wrapper {
    margin: 0 auto 5px;
    .slick-slider {
      margin: 0;
      height: 100%;
      padding: 0;
      .slick-slide {
        width: 30px;
        height: 30px;
        margin: 0;
      }
      .slick-list {
        width: 224px;
        height: 100%;
        margin-#{$ldirection}: 4px;
        @include breakpoint($bp--medium-up) {
          width: 180px;
        }
      }
      .slick-next,
      .slick-prev {
        display: block;
        top: 0;
        margin-block-start: 4px;
        width: 46px;
        height: 30px;
        @include breakpoint($bp--medium-up) {
          top: 0;
          margin-block-start: 3px;
          width: 54px;
          height: 34px;
        }
        &:before {
          content: '';
          display: none;
        }
        .elc-icon {
          margin-block-start: 5px;
          width: 20px;
          height: 20px;
          @include breakpoint($bp--medium-up) {
            margin-block-start: 6px;
          }
        }
        &.slick-disabled {
          display: none;
        }
        background: unset;
      }
      .slick-next {
        #{$rdirection}: 0;
        margin-inline-end: -53px;
        @include breakpoint($bp--medium-up) {
          margin-inline-end: -61px;
        }
        @if $text-direction == 'rtl' {
          #{$ldirection}: 0 !important;
          margin-inline-end: -50px;
          @include breakpoint($bp--medium-up) {
            margin-inline-end: -58px;
          }
        }

        .elc-icon {
          margin-inline-start: 7px;
        }
      }
      .slick-prev {
        #{$rdirection}: unset;
        #{$ldirection}: 0;
        margin-inline-start: -51px;
        @include breakpoint($bp--medium-up) {
          margin-inline-start: -58px;
        }
        @if $text-direction == 'rtl' {
          #{$rdirection}: 0;
          margin-inline-start: -46px;
          @include breakpoint($bp--medium-up) {
            margin-inline-start: -61px;
          }
        }
        .elc-icon {
          margin-inline-start: 20px;
          @include breakpoint($bp--medium-up) {
            margin-inline-start: 28px;
          }
        }
      }
      .elc-slider-arrow-left,
      .elc-slider-arrow-right {
        -webkit-mask: unset;
      }
      .elc-slider-arrow-left {
        background: url('#{$base-theme-path}svg-icons/src/icon--chevron-up.svg');
        transform: rotate(-90deg);
        @include breakpoint($bp--medium-up) {
          margin-inline-start: 0;
        }
      }
      .elc-slider-arrow-right {
        background: url('#{$base-theme-path}svg-icons/src/icon--chevron-up.svg');
        transform: rotate(90deg);
        @include breakpoint($bp--medium-up) {
          margin-inline-end: -4px;
        }
      }
      .slick-track {
        margin: 0 auto;
        padding: 0;
      }
      .elc-shade-image {
        margin: 4px 4px;
        width: 30px;
        height: 30px;
        @include breakpoint($bp--medium-up) {
          margin: 4px 0 1px;
        }
        &[data-hex='#ffffff'],
        &[data-hex='#fffdf8'],
        &[color='#ffffff'],
        &[color='#fffdf8'] {
          .elc-absolute-shade-wrapper {
            border: 1px solid $color-cta-grey;
          }
        }
      }
    }
    .elc-product-shade-picker-title,
    .elc-subtitle--3 {
      &[data-hex='#ffffff'],
      &[data-hex='#fffdf8'],
      &[color='#ffffff'],
      &[color='#fffdf8'] {
        // override inline color from JS, background is white
        color: $color-cta-grey !important;
      }
    }
    .elc-shade-icon {
      &[data-hex='#ffffff'],
      &[data-hex='#fffdf8'],
      &[color='#ffffff'],
      &[color='#fffdf8'] {
        border: 1px solid $color-cta-grey;
      }
    }
    .elc-product-shade-picker-title {
      text-align: center;
      font-family: $product-shade-name;
      margin-top: 10px;
      text-transform: uppercase;
      color: $color-shade-name;
    }
    .elc-shade-image-selected {
      .elc-absolute-shade-wrapper {
        &:before {
          content: '';
          width: 8px;
          height: 8px;
          background: $color--white;
          position: absolute;
          top: 11px;
          #{$ldirection}: 11px;
          border-radius: 10px;
        }
      }
    }
    .elc-absolute-shade-wrapper {
      width: 30px;
      height: 30px;
      top: 0;
    }
    .elc-product-shade-picker-slider {
      height: 40px;
      max-width: 250px;
      margin: 0 auto;
      @include breakpoint($bp--medium-up) {
        height: 48px;
      }
    }
  }
  .elc-rating-component-container {
    position: absolute;
    z-index: 2;
  }
  .elc-rating {
    .elc-stars-simplified {
      .elc-stars-simplified-stars {
        height: 14px;
        position: absolute;
        overflow: hidden;
        margin-top: -7px;
        .os--Windows & {
          margin-top: -9px;
        }
      }
    }
  }
  .elc-stars-simplified-stars,
  .elc-stars-simplified {
    &:before {
      background: url(/sites/esteelauder/themes/estee_base/img/icons/src/el_star.svg) repeat-x;
      background-size: 14px;
      content: '';
      display: block;
      height: 14px;
      position: absolute;
      top: -2px;
      width: 70px;
    }
  }
  .elc-stars-simplified:before {
    filter: contrast(0);
  }
  .elc-add-to-bag-sku-inventory-status-message {
    text-align: center;
    width: 100%;
    margin: 0;
    order: 2;
    @include body-text--small;
    p {
      color: $color--tos-red;
    }
  }
}
